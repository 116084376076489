import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./News.css";
import "../theme.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

function News(props) {
  return (
    <React.Fragment>
      <Nav />

      <div className="container shows-container">
        <div className="content-wrapper">
          <h1 className="shows-header">NEWS</h1>

          {/* copy and paste from lines 18 to 21 to make a new post */}
          <div className="news-post">
            <h2 className="news-title">EAST COAST TOUR IN DECEMBER <span className="news-date"> OCTOBER 27, 2024</span> </h2>
            <p className="news-body">  12/13 - Hart Bar - NYC, NY <br></br> 12/14 - The Meadow - Philadelphia, PA <br></br> 12/15 - Sketchy Shed - Frederick, MD <br></br></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SOUTHWEST TOUR IN MAY <span className="news-date"> MAY 2, 2024</span> </h2>
            <p className="news-body">  5/16 The Hive - Flagstaff, AZ <br></br> 5/17 Longhair Records - Albuquerque, NM <br></br> 5/18 The 805 - Amarillo, TX <br></br> 5/19 Rubber Gloves - Denton, TX <br></br> 5/21 1810 Ojeman - Houston, TX <br></br> 5/22 The Starlighter - San Antonio, TX <br></br> 5/23 Valhalla - Austin, TX <br></br> 5/27 Blondie's - Tucson, AZ <br></br> 5/28 Birdcage Comics - San Bernadino, CA<br></br></p>
            </div>

          <div className="news-post">
            <h2 className="news-title">UK TOUR IN APRIL <span className="news-date"> APRIL 2, 2024</span> </h2>
            <p className="news-body">  4/20 The Grafton Arms - Manchester, UK <br></br> 4/21 Centrala - Birmingham, UK  <br></br> 4/22 New River Studios - London, UK <br></br></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">EAST COAST TOUR IN DECEMBER <span className="news-date">NOVEMBER 7, 2023</span> </h2>
            <p className="news-body">  12/1 Bar Freda - Queens, NY <br></br> 12/2 The Clownhouse - New Brunswick, NJ  <br></br> 12/3 The Meadow - Philadelphia, PA <br></br></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SEPTEMBER GIG ROUND UP <span className="news-date">SEPTEMBER 9, 2023</span> </h2>
            <p className="news-body">  
9.9 Junior High - Glendale, CA
9.14 Labyrinth Art Collective -Fresno, CA
9.15 Golden Bull - Oakland, CA
9.16 Snake Farm - Portland, OR
9.17 Blue Moon Tavern - Seattle, WA
9.18 John Henry’s - Eugene, OR
9.19 Holland Project - Reno, NV
9.20 The Smell -LA, CA
9.21 La Santa - Santa Ana, CA
9.23 FTG Warehouse - Santa Ana, CA
<br></br></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">FULL VIDEO OF THE TERAGRAM SHOW UP ON YOUTUBE <span className="news-date">AUGUST 23, 2022</span> </h2>
            <p className="news-body">  Check it out <a href="https://www.youtube.com/watch?v=iy6GOUPDqxc">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">WEST COAST TOUR IN SEPTEMBER <span className="news-date">AUGUST 8, 2023</span> </h2>
            <p className="news-body">  9/14 Tioga Segura Fresno, CA <br></br> 9/15 Golden Bull - Oakland, CA  <br></br> 9/16 Snake Farm Portland, OR <br></br> 9/17 Blue Moon Tavern Seattle, WA<br></br> 9/18 John Henry's Eugene, OR <br></br> 9/19 Holland Project Reno, NV<br></br> 9/20 The Smell LA, CA <br></br></p>
            </div>7/23
          <div className="news-post">
            <h2 className="news-title">TWO SHOWS TODAY AT TERAGRAM BALLROOM <span className="news-date">JULY 23, 2023</span> </h2>
            <p className="news-body">  1pm and 7pm, the late show is sold out but a few tickets are available at the door for the matinee show <br></br></p>
            </div>7/23
          <div className="news-post">
            <h2 className="news-title">UPCOMING SHOWS <span className="news-date">FEBRUARY 4, 2023</span> </h2>
            <p className="news-body">  6/17 Shadow Brewing San Fernando, CA <br></br> 6/23 Midnight Hour San Fernando, CA  <br></br> 6/29 Que Sera Long Beach, CA <br></br>7/23 Teragram Ballroom LA, CA <br></br></p>
            </div>
            <div className="news-post">
            <h2 className="news-title">7/23 AT TERAGRAM <span className="news-date">MAY 15, 2023</span> </h2>
            <p className="news-body">  With Vs Self, Knumears, Party Hats & Widowdusk<br></br></p>
            </div>
 <div className="news-post">
            <h2 className="news-title">LAST MINUTE SHOW WITH HIRS COLLECTIVE TONIGHT! <span className="news-date">APRIL 17th, 2023</span> </h2>
            <p className="news-body">  With the Groans at Slipper Clutch, roll out!<br></br></p>
            </div>
            <div className="news-post">
            <h2 className="news-title">SAN DIEGO TONIGHT WITH HIRS COLLECTIVE! <span className="news-date">APRIL 16th, 2023</span> </h2>
            <p className="news-body">  At the Til Two Club!<br></br></p>
            </div>
 <div className="news-post">
            <h2 className="news-title">FLOOD MAGAZINE IS STREAMING HEALTH GOTH NOW <span className="news-date">FEBRUARY 3, 2023</span> </h2>
            <p className="news-body">  Stream it today before it drops tomorrow <a href="https://floodmagazine.com/125437/belted-sweater-health-goth-first-listen/">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">REQUIEM PREMERING ON CHORUS.FM <span className="news-date">FEBRUARY 2, 2023</span> </h2>
            <p className="news-body">  Listen to the Killing Joke cover  <a href="https://chorus.fm/features/belted-sweater-health-goth-album-stream/">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SWEET WRITEUP IN THE GTC <span className="news-date">NOVEMBER 22, 2022</span> </h2>
            <p className="news-body">  Check it out <a href="https://globaltexanchronicles.com/belted-sweater/">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">UNDERGROUND AND PROUD WRITEUP <span className="news-date">NOVEMBER 19, 2022</span> </h2>
            <p className="news-body">  Read it <a href="https://undergroundnproud.com/2022/11/21/belted-sweater-new-single-dominion-sisters-of-mercy-cover/">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">CHAOS IN LONG BEACH TONIGHT<span className="news-date">NOVEMBER 18, 2022</span> </h2>
            <p className="news-body">  Might bust out a Sepultura cover at the Que Sera</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">TWO SHOWS THIS WEEK IN POMONA<span className="news-date">NOVEMBER 9, 2022</span> </h2>
            <p className="news-body">  11/10 at DBA256 and 11/13 at the Haven </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SAN DIEGO TONIGHT<span className="news-date">OCTOBER 23, 2022</span> </h2>
            <p className="news-body">  At the Che Cafe!!! </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SINGLE RELEASE SHOW TONIGHT<span className="news-date">OCTOBER 22, 2022</span> </h2>
            <p className="news-body">  At the LGBT Center in Santa!!! </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">DOMINION OUT NOW!!! <span className="news-date">OCTOBER 21, 2022</span> </h2>
            <p className="news-body">  Stream it here <a href="https://linktr.ee/beltedsweater">here</a></p>
            </div>
            <div className="news-post">
            <h2 className="news-title">HEALTH GOTH <span className="news-date">OCTOBER 20, 2022</span> </h2>
            <p className="news-body">  Going full Alien Ant Farm/the Ataris and releasing a three song covers EP called Health Goth. The first single, Dominion, originally by the Sisters of Mercy, comes out 10/21, and the EP drops in February -also finishing up LP2, but first things first. The EP also has covers of Requiem by Killing Joke and House of Jealous Lovers by the Rapture. The idea initially was just to play a few covers live, but I ended up really liking the goth Diplo realness the tracks were serving, so here we are #yolo. The amazing art is once again by Tom Lowell</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">MORE SHOWS <span className="news-date">OCTOBER 13, 2022</span> </h2>
            <p className="news-body">  11.10 at DBA256 in Pomona, 11.26 at Que Sera in Long Beach, 12.14 at the Sardine in San Pedro</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">12/1 IN BROOKLYN <span className="news-date">OCTOBER 2, 2022</span> </h2>
            <p className="news-body">  At Soverign, more East Coast dates soon! </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SHOW TONIGHT IN HESPERIA <span className="news-date">SEPTEMBER 11, 2022</span> </h2>
            <p className="news-body">  At C47 DIY with Vs Self, Tolls, and Vientre #dmforaddress #askapunk</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SWEET WRITE UP ABOUT THE TOUR WITH MENSA DEATHSQUAD AND MARS RODRIGUEZ <span className="news-date">AUGUST 26, 2022</span> </h2>
            <p className="news-body">  Check it out <a href="https://newnoisemagazine.com/news-mensa-deathsquad-to-tour-southern-california-with-mars-rodriguez-belted-sweater/">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">UPCOMING SHOWS <span className="news-date">AUGUST 21, 2022</span> </h2>
            <p className="news-body">  9/2 FTG Warehouse Santa Ana, CA <br></br>9/4 Til Two Club San Diego, CA <br></br> 9/5 Supply and Demand Long Beach, CA <br></br> 10/22 LGBT Center Santa Ana, CA<br></br> 10/23 Che Cafe San Diego, CA <br></br>11/25 924 Gilman Berkeley, CA </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SHOW TONIGHT IN GLENDALE <span className="news-date">AUGUST 13, 2022</span> </h2>
            <p className="news-body">  With Faetooth, Candy Picnic and Comet at Junior High.  It's an early one, on first at 7:20pm <br></br> </p>
            </div>
            <div className="news-post">
            <h2 className="news-title">PLANNED PARENTHOOD BENEFIT SHOW TONIGHT IN FULLERTON <span className="news-date">AUGUST 7TH, 2022</span> </h2>
            <p className="news-body">  With Uncultured, Josie Wreck, Roman Graves, and the Dead Dogs at Programme Skate and Sound.  All proceeds will be donated to Planned Parenthood <br></br> </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">NEW TOUR DATES <span className="news-date">MAY 10, 2022</span> </h2>
            <p className="news-body">  5/29 MeowMeowz Pasadena, CA <br></br>5/30 Til Two Club San Diego, CA <br></br> 6/3 The Smell LA, CA <br></br> 6/4 La Maison Kabob Fresno, CA <br></br> 6/5 Elbo Room Oakland, CA <br></br> F Yessssss, with Josie Wreck and Roman Graves </p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SWEET WRITE UP ABOUT THE DENVER SHOW IN WESTWORD <span className="news-date">OCTOBER 30, 2021</span> </h2>
            <p className="news-body">  Check it out <a href="https://www.westword.com/music/belted-sweater-is-chris-gregorys-new-solo-project-12593039">here</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">TOUR <span className="news-date">OCTOBER 10, 2021</span> </h2>
            <p className="news-body">  10/29 miniBar Kansas City,MO <br></br>10/30 Queer Nite Omaha,NE  <br></br>10/31 Wax Trax Records Denver,CO <br></br>11/1 Radio Free Provo Provo, UT <br></br>11/12 Double Down Saloon Las Vegas, NV  <br></br> SO EXCITED!!!</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">FREE SHOW 9/12 AT LITTLE JOY <span className="news-date">SEPTEMBER 3, 2021</span> </h2>
            <p className="news-body">  With Hurt Hawks and Witchborn, proof of vaccination required, 21+, let's shred.</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">HAPPY SUNDAYS MUSIC FESTIVAL <span className="news-date">AUGUST 20, 2021</span> </h2>
            <p className="news-body">  Super stoked to be playing this years Happy Sundays Free Music Festival in Long Beach on 8/29.  There's a ton of killer bands all day, a shuttle between venues, and its free! I'm playing the DiPiazza's stage at 6pm.</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">DEBUT LIVE SHOW THIS THURSDAY!!! <span className="news-date">AUGUST 9, 2021</span> </h2>
            <p className="news-body">  Finally playing my first solo show this Thursday at the Que Sera for Queer Thursdays.  I’ll be shouting over ostentatious drum fills with some awesome bands, queer artists and also there’s a dance party so let’s boogie!  #MASK4MASK</p>
            </div>
          <div className="news-post">
            <h2 className="news-title">POZ IS OUT NOW!!! <span className="news-date">JUNE 25, 2021</span> </h2>
            <p className="news-body"> Poz, my new song/sweet sweet collaboration with the HIRS Collective is out now! It’s on their new record, The Third 100 Songs, which is ridiculously amazing and I’m stoked to be a small part of it, shouting over their sweet riffs. <a href="https://open.spotify.com/track/3aNh6OlkPngB0ZWFq2URGj?si=193eedb0f1f4425a">Check it out!</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">NEW INTERVIEW ON THE NEW HELL BENT FOR METAL EPISODE <span className="news-date">JUNE 14, 2021</span> </h2>
            <p className="news-body">I had an awesome conversation about the making of I’m fucking delightful with Matt on the new episode of the LGBT+ metal podcast Hell Bent for Metal.  We discuss loving both the Dillinger Escape Plan and Fifth Harmony, why it’s important to hear super queer lyrics,  and making the record my closeted fourteen year old self would want to listen to, basically Power, Corruption & Lies but with Al Cisneros on vocals and Damon Che on drums.  Plus I tried not to ramble and only made a few Dad jokes. Listen on <a href="https://podcasts.apple.com/us/podcast/hell-bent-for-metal/id1538404697?i=1000525401515" target="_blank">Apple Podcasts.</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SWEET COLLAB WITH THE HIRS COLLECTIVE "POZ" COMES OUT 6/25 <span className="news-date">JUNE 4, 2021</span> </h2>
            <p className="news-body">So I contributed vocals and lyrics to a new, unreleased HIRS Collective song, Poz.  It’s about pharmaceutical price gouging during the onset of the AIDS crisis.  It’s on their new compilation album, The Third 100 Songs, featuring sweet collabs with members of the Screaming Females, RVIVR, & Thou among others, and comes out 6/25 on Get Better Records. Pre order it <a href="https://getbetterrecords.com/products/the-hirs-collective-third-100-songs" target="_blank">here.</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">AWESOME INTERVIEW IN THE DEBUT ISSUE OF FACEFUCK FANZINE <span className="news-date">MARCH 24, 2021</span> </h2>
            <p className="news-body">Check it out <a href="https://www.instagram.com/facefuck.fanzine/" target="_blank">here.</a></p>
            </div>
          <div className="news-post">
            <h2 className="news-title">SWEET ALBUM REVIEW FROM GIRL AT THE ROCK SHOWS <span className="news-date">FEBRUARY 25, 2021</span> </h2>
            <p className="news-body">Read it <a href="https://girlattherockshows.com/2021/02/24/belted-sweater-im-fucking-delightful/" target="_blank">here.</a></p>
          </div>
          <div className="news-post">
            <h2 className="news-title">TODAY'S TERRIBLE HITS <span className="news-date">FEBRUARY 15, 2021</span> </h2>
            <p className="news-body">The aging homosexual charm offensive is on the new Terrible Records playlist with a bunch of other awesome tracks. Check it out <a href="https://open.spotify.com/playlist/2z60pBsqsjfQP3szueJRsE" target="_blank">here.</a></p>
          </div>
          <div className="news-post">
            <h2 className="news-title">AWESOME ALBUM WRITE UP WITH LIMP WRIST REFERENCES!!! <span className="news-date">DECEMBER 4, 2020</span> </h2>
            <p className="news-body">F YES. <a href="https://newnoisemagazine.com/new-noise-bandcamp-picks-december-2020/">Check it here.</a> </p>
          </div>
          <div className="news-post">
            <h2 className="news-title">NEW WEBSITE UPDATES <span className="news-date">DECEMBER 1, 2020</span> </h2>
            <p className="news-body">OMG</p>
          </div>

          

          {/* <div className="news-post">
            <h2 className="news-title">CHRIS GREGORY MAKES STUPID NEWS SECTION ON WEBSITE / <span className="news-date">DECEMBER 24, 2020</span> </h2>
            <p className="news-body">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, a velit. Aut molestias ab nisi ratione exercitationem recusandae, et ex consectetur minima itaque error modi repellendus deserunt earum. Reiciendis quia in eveniet sit consectetur ducimus eos doloribus recusandae distinctio, dolores, veniam minus cupiditate facilis assumenda dignissimos, eligendi dolore accusantium expedita.</p>
          </div> */}

          {/* <div className="news-post">
            <h2 className="news-title">CHRIS GREGORY MAKES STUPID NEWS SECTION ON WEBSITE / <span className="news-date">DECEMBER 24, 2020</span> </h2>
            <p className="news-body">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, a velit. Aut molestias ab nisi ratione exercitationem recusandae, et ex consectetur minima itaque error modi repellendus deserunt earum. Reiciendis quia in eveniet sit consectetur ducimus eos doloribus recusandae distinctio, dolores, veniam minus cupiditate facilis assumenda dignissimos, eligendi dolore accusantium expedita.</p>
          </div> */}
          
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default News;
